@import "../../../styles/base/abstracts";

.hbs-tease-row-container .hbs-tease-row.hbs-tease-row--2-up {
  .hbs-tease-row__tease-mod {
    @include mq($bp-tablet) {
      width: $col-8;
    }

    @include mq($bp-desktop) {
      width: $col-8;
    }
  }
}

@import "../../../styles/base/abstracts";

.hbs-tease-row-container {
  .hbs-tease-row {
    @include list-reset;
    @include hide-scrollbars;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    //outline getting clipped by overflow settings
    padding: 8px 0 8px 0;
    margin: -8px 0 -8px 0;

    @include mq(max, $bp-desktop) {
      @include margin(x #{-$grid-gutter-mobile});
      @include padding(x $grid-padding-mobile);
      display: flex;
      flex-flow: row wrap;

      @include mq($bp-full-grid) {
        @include margin(x #{-$grid-gutter});
        @include padding(x $grid-padding);
      }
    }

    @include mq($bp-desktop) {
      @include grid-parent($offset: true);
    }
  }

  .hbs-tease-row .hbs-tease-row__tease-mod,
  .hbs-tease-row--4-up.hbs-tease-row--person-tease .hbs-tease-row__tease-mod {
    @include grid-child;
    display: flex;
    margin-top: 0;
    width: $col-12;

    @include mq($bp-tablet) {
      width: $col-6;
    }

    @include mq($bp-desktop) {
      width: $col-one-third;
    }

    @include mq($bp-desktop-xl) {
      width: $col-4;
    }
  }

  .hbs-tease-row.hbs-tease-row--wrap.hbs-tease-row--person-tease
    .hbs-tease-row__tease-mod,
  .hbs-tease-row.hbs-tease-row--no-wrap.hbs-tease-row--person-tease
    .hbs-tease-row__tease-mod,
  .hbs-tease-row--person-tease .hbs-tease-row__tease-mod {
    width: $col-5;

    @include mq($bp-tablet-xl) {
      width: $col-3;
    }
  }

  .hbs-tease-row--8-up.hbs-tease-row--person-tease .hbs-tease-row__tease-mod {
    .hbs-global-align-full & {
      @include mq($bp-desktop-lg) {
        width: $col-2;
      }
    }
    .hbs-global-align-wide & {
      @include mq($bp-desktop-lg) {
        width: nested-cols(2, 12);
      }
    }
  }

  .hbs-tease-row--6-up .hbs-tease-row__tease-mod {
    .hbs-global-align-full & {
      @include mq($bp-desktop-lg) {
        width: $col-one-sixth;
      }
    }

    .hbs-global-align-wide & {
      @include mq($bp-desktop-lg) {
        width: nested-cols(2, 12);
      }
    }
  }

  .hbs-tease-row--1-up .hbs-tease-row__tease-mod {
    width: 100% !important;
  }

  // Wrapping
  .hbs-tease-row.hbs-tease-row--no-wrap {
    flex-wrap: nowrap;

    @include mq($bp-desktop) {
      @include margin(x #{-$grid-gutter});
      @include padding(x $grid-padding);
      width: calc(100% + #{$grid-gutter * 2});
    }

    .hbs-tease-row__tease-mod {
      flex-shrink: 0;
    }

    &:not(.hbs-tease-row--2-up) {
      .hbs-tease-row__tease-mod:has(.hbs-article-tease--small) {
        @include mq($bp-desktop) {
          max-width: $col-one-third;
        }
      }
    }

    &:not(.hbs-tease-row--person-tease) {
      &.hbs-tease-row--4-up,
      &.hbs-tease-row--6-up,
      &.hbs-tease-row--8-up {
        .hbs-tease-row__tease-mod {
          @include mq($bp-tablet) {
            width: $col-6;
          }

          @include mq($bp-desktop) {
            width: $col-5;
          }

          @include mq($bp-desktop-xl) {
            width: $col-4;
          }

          &:has(.hbs-article-tease--small) {
            @include mq($bp-desktop) {
              width: $col-4;
            }
          }
        }
      }
    }
  }

  .hbs-tease-row.hbs-tease-row--wrap {
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: -$grid-gutter-mobile;

    @include mq($bp-tablet) {
      margin-bottom: -$grid-gutter;
    }

    .hbs-tease-row__tease-mod {
      @include padding(x x $grid-gutter-mobile);
      flex-shrink: initial;

      @include mq(max, $bp-tablet) {
        width: 100%;
      }
      @include mq($bp-tablet, $bp-desktop) {
        width: $col-8;
      }

      @include mq($bp-tablet) {
        @include padding(x x $grid-gutter);
      }
    }

    &.hbs-tease-row--2-up .hbs-tease-row__tease-mod {
      width: 50%;
    }

    @include mq($bp-tablet) {
      &:not(.hbs-tease-row--courses) {
        &.hbs-tease-row--3-up .hbs-tease-row__tease-mod,
        &.hbs-tease-row--4-up .hbs-tease-row__tease-mod {
          width: 50%;
        }

        &.hbs-tease-row--5-up .hbs-tease-row__tease-mod,
        &.hbs-tease-row--6-up .hbs-tease-row__tease-mod {
          width: calc(100% / 3);
        }

        &.hbs-tease-row--7-up .hbs-tease-row__tease-mod {
          width: calc(100% / 3);
        }

        &.hbs-tease-row--8-up .hbs-tease-row__tease-mod {
          width: calc(100% / 4);
        }
      }
    }
    @include mq($bp-desktop) {
      &:not(.hbs-tease-row--courses) {
        &.hbs-tease-row--3-up .hbs-tease-row__tease-mod {
          width: calc(100% / 3);
        }

        &.hbs-tease-row--4-up .hbs-tease-row__tease-mod {
          width: 25%;
        }

        &.hbs-tease-row--5-up .hbs-tease-row__tease-mod {
          width: 20%;
        }

        &.hbs-tease-row--6-up .hbs-tease-row__tease-mod {
          width: calc(100% / 6);
        }

        &.hbs-tease-row--7-up .hbs-tease-row__tease-mod {
          width: calc(100% / 7);
        }

        &.hbs-tease-row--8-up .hbs-tease-row__tease-mod {
          width: calc(100% / 8);
        }
      }
    }
  }
}

.hbs-tease-row-container + .hbs-tease-row-container {
  @include block-spacing(top, $size: lg);
}
